import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import DefaultLayout from '../components/layouts'

import get from 'lodash/get'

// import 'prismjs/themes/prism-coy.css'
import 'prismjs/themes/prism-tomorrow.css'

export default function ArticlesListing(props) {
  const { allMarkdownRemark, site } = props.data // data.markdownRemark holds our post data
  const posts = get(props.data, 'allMarkdownRemark.posts')

  return (
    <DefaultLayout siteMetadata={site.siteMetadata} {...props}>
      <div className="grid-container" id="articles">
        <Helmet
          title="Articles on iOS Dev Recipes"
          description="All of our original articles, guides and references for iOS and Swift developers"
        />
        <section className="grid-x grid-padding-x align-center">
          <div className="cell auto shrink">
            <h1>All of our articles, in order</h1>
            <p>
              New posts come 2-3 times per month. Sign up for the mailing list,
              follow us on Twitter or grab the RSS feed to get the latest
              updates
            </p>

            <a href="/rss.xml" className="rss">
              <img src="/img/rss.svg" height="18px" />
              RSS Feed
            </a>

            <hr />
          </div>
        </section>
        <section className="grid-x grid-padding-x align-center">
          {posts.map(({ article }, idx) => (
            <article key={article.id} className="summarized cell">
              <Link
                to={article.frontmatter.path}
                title={article.frontmatter.linkTitle}
                className=""
              >
                <h2>{article.frontmatter.linkTitle}</h2>
                <p>
                  Published on: {article.frontmatter.firstPublishedOn} •{' '}
                  {article.timeToRead} minute read
                </p>

                <p>{article.frontmatter.summary}</p>
              </Link>
            </article>
          ))}
        </section>
        <Helmet
          title="Articles and Guides | iOS Dev Recipes"
          meta={[
            {
              name: 'description',
              content:
                'Original guides and articles for iOS Developers covering a broad range of' +
                ' topics designed to help you find what you need and get back to work quickly.',
            },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: '@iosdevrecipes' },
            {
              name: 'twitter:title',
              content: 'Articles and Guides | iOS Dev Recipes',
            },
            {
              name: 'twitter:description',
              content:
                'Original guides and articles for iOS Developers covering a broad range of' +
                ' topics designed to help you find what you need and get back to work quickly.',
            },
            {
              name: 'twitter:image',
              href: 'https://www.iosdev.recipes/img/logos/logo-1024.png',
            },
            {
              name: 'og:url',
              content: `https://www.iosdev.recipes/articles/`,
            },
            {
              name: 'og:title',
              content: 'Articles and Guides | iOS Dev Recipes',
            },
            {
              name: 'og:description',
              content:
                'Original guides and articles for iOS Developers covering a broad range of' +
                ' topics designed to help you find what you need and get back to work quickly.',
            },
            {
              name: 'og:image',
              href: 'https://www.iosdev.recipes/img/logos/logo-1024.png',
            },
          ]}
          htmlAttributes={{ lang: 'en-US' }}
        >
          <script type="application/ld+json" id="json-ld">
            {`
[
  {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "iOS Dev Recipes",
    "url": "${props.data.site.siteMetadata.siteUrl}",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.iosdev.recipes/img/logos/logo-1024.png",
      "width": 1024,
      "height": 1024
    },
    "sameAs": [
      "http://www.twitter.com/iosdevrecipes"
    ]
  }
]
`}
          </script>
        </Helmet>{' '}
      </div>
    </DefaultLayout>
  )
}

export const pageQuery = graphql`
  query allArticlesListing {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }

    allMarkdownRemark(
      filter: {
        frontmatter: { isArticle: { eq: true }, isPublished: { eq: true } }
      }
      sort: { fields: [frontmatter___firstPublishedOn], order: DESC }
    ) {
      posts: edges {
        article: node {
          id
          frontmatter {
            path
            lastUpdatedDate(formatString: "MMMM DD, YYYY")
            firstPublishedOn
            isPublished
            isArticle
            title
            description
            summary
            linkTitle
            tags
          }
          timeToRead
        }
      }
    }
  }
`
